import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getControls } from "../../../../_api/Controle";
import { Button } from "../../../../_shared/Button";
import InputSwitch from "../../../layout/forms/InputSwitch";

const StatusControle = {
    SUCCESS: "Contrôle Valide",
    PENDING: "En cours",
    WAITING_DEPOT: "En attente de dépot",
    WAITING_VERIF: "En attente de votre controle",
    FAILED: "Contrôle Refusé",
    REVISION: "En attente de justificatif",
};

export const ListMandats = () => {
    const { addToast } = useToasts();

    const history = useHistory();
    const [controls, setControls] = useState([]);
    const [filteredControls, setFilteredControls] = useState([]);
    const [archivedProtege, setisArchived] = useState(false);

    useEffect(() => {
        getControls(addToast).then((resp) => {
            setControls(resp.data);
            const filteredData = resp.data.filter(
                (item) => item.isArchived === archivedProtege
            );
            setFilteredControls(filteredData);
        });
    }, []);

    const [selectedCategory, setSelectedCategory] = useState("");
    const filteredData = selectedCategory
        ? filteredControls.filter((item) => item.status === selectedCategory)
        : filteredControls;

    const [currentPage, setCurrentPage] = useState(1); // Page actuelle
    const [itemsPerPage] = useState(10); // Nombre de lignes par page

    // Calculer le nombre total de pages
    const totalPages = Math.ceil(filteredControls.length / itemsPerPage);

    // Déterminer les éléments à afficher pour la page actuelle
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setCurrentPage(1); // Reset page to 1 when filtering
    };

    // Récupérer les catégories uniques pour le filtre
    const uniqueCategories = [
        ...new Set(filteredControls.map((item) => item.status)),
    ];

    return (
        <>
            <div>
                <div className="flex justify-end m-2">
                    <InputSwitch
                        id="isArchivedProtege"
                        className="column-item-flex-1"
                        onChange={() => {
                            const filteredData = controls.filter(
                                (item) => item.isArchived === !archivedProtege
                            );
                            setisArchived(!archivedProtege);
                            setFilteredControls(filteredData);
                        }}
                        label="Mandats"
                        isSwitchMajeur={true}
                        onText="Archivés"
                        offText="Courants"
                        value={archivedProtege}
                    />
                    <Button
                        type={"empty"}
                        name={"Ajouter un mandat"}
                        action={() => {
                            history.push(`/create_mandat`);
                        }}
                    />
                </div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Numéro de RG
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Portalis
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                    <select
                                        className="ml-2 p-1 text-xs text-gray-700 bg-white border border-gray-300 rounded"
                                        value={selectedCategory}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="">Tous</option>
                                        {uniqueCategories.map(
                                            (category, index) => (
                                                <option
                                                    key={index}
                                                    value={category}
                                                >
                                                    {category}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Représentant
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Majeur
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => {
                                return (
                                    <tr
                                        key={index}
                                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                    >
                                        <td className="px-6 py-4">
                                            {item.rg_number}
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.portalis_number}
                                        </td>
                                        <td className="px-6 py-4">
                                            {StatusControle[item.status]}
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.tutor_name || "Non renseigné"}
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.name
                                                ? item.second_name +
                                                  " " +
                                                  item.name
                                                : item.second_name}
                                        </td>
                                        <td className="px-6 py-4">
                                            <Link
                                                to={"/mandat/" + item._id}
                                                className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2"
                                            >
                                                Voir
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                            {currentItems.length === 0 && (
                                <tr className=" text-center odd:bg-white font-bold border-b dark:border-gray-700 h-12">
                                    <td colSpan={5}>
                                        Il n'y a actuellement aucun controle de
                                        réaliser.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => paginate(index + 1)}
                            className={`px-4 py-2 mx-1 text-sm font-medium text-white ${
                                currentPage === index + 1
                                    ? "bg-blue-500"
                                    : "bg-gray-700"
                            } rounded-md hover:bg-blue-700`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};
