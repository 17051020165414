import React from "react";

export const DocUpload = (props: {
    isReadOnly?: boolean;
    show: boolean;
    value: File[];
    set: (file: File[] | null) => void;
    title: string;
    uniqueId: string;
}) => {
    const { show, value, set, title, uniqueId, isReadOnly } = props;

    const downloadFile = () => {
        if (value && value.length > 0) {
            value.forEach((file) => {
                const url = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = url;
                link.download = file.name; // Nom du fichier
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url); // Nettoyage de l'URL temporaire
            });
        }
    };
    return (
        <>
            {show && (
                <form className="ml-4 doc-upload-form" style={containerStyle}>
                    <label
                        htmlFor={`file-upload-${uniqueId}`}
                        style={labelStyle}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            width="40"
                            height="40"
                            style={{ marginRight: "10px" }} // Espacement entre l'icône et le texte
                        >
                            <rect
                                x="2"
                                y="2"
                                width="60"
                                height="60"
                                rx="10"
                                ry="10"
                                fill="none"
                                stroke="#4caf50"
                                strokeWidth="2"
                                strokeDasharray="5,5"
                            />
                            <path
                                d="M22 16h14l8 8v24a2 2 0 0 1-2 2H22a2 2 0 0 1-2-2V18a2 2 0 0 1 2-2z"
                                fill="#4caf50"
                            />
                            <path
                                d="M36 16v8h8"
                                fill="none"
                                stroke="#fff"
                                strokeWidth="2"
                            />
                            <path
                                d="M32 32v-6m0 6-3-3m3 3 3-3"
                                fill="none"
                                stroke="#fff"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </svg>
                        <span
                            style={textStyle}
                            onClick={() => {
                                if (isReadOnly) {
                                    downloadFile();
                                }
                            }}
                        >
                            {!isReadOnly && value && value.length > 0
                                ? `${title} : ${value.length} fichier(s)`
                                : title}
                            {isReadOnly && <> {value.length} fichier(s)</>}
                        </span>
                    </label>

                    {!isReadOnly && (
                        <input
                            type="file"
                            id={`file-upload-${uniqueId}`}
                            multiple // Ajoutez cet attribut pour permettre la sélection de plusieurs fichiers
                            onChange={(e) => {
                                if (e.target.files) {
                                    set(Array.from(e.target.files)); // Utilisez Array.from pour convertir FileList en tableau
                                }
                            }}
                            style={{ display: "none" }}
                        />
                    )}
                </form>
            )}
        </>
    );
};

// Styles CSS en ligne pour un alignement propre
const containerStyle = {
    display: "flex", // Utilise flexbox pour placer les éléments côte à côte
    alignItems: "center", // Aligne verticalement le SVG et le texte
    gap: "10px", // Espacement entre les éléments (optionnel si `marginRight` est utilisé sur le SVG)
    cursor: "pointer", // Rend toute la zone cliquable
};

const labelStyle = {
    display: "flex",
    alignItems: "center", // Aligne le SVG et le texte verticalement
    cursor: "pointer", // Transforme le label en bouton cliquable
};

const textStyle = {
    fontSize: "16px",
    color: "#4caf50",
    fontWeight: "500",
};

const buttonStyle = {
    marginTop: "10px",
    padding: "8px 12px",
    backgroundColor: "#4caf50",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
};
