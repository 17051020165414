import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    CIVILITY_OPTIONS,
    capitalizeFirst,
    checkPrevious,
    returnOnlyNumbers,
} from "../../../constants/Utils";
import IAddress from "../../../interfaces/IAddress";
import IEmail from "../../../interfaces/IEmail";
import IPhone from "../../../interfaces/IPhone";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import { request } from "../../../utils/axios-utils";
import ArrowBack from "../../layout/ArrowBack";
import Divider from "../../layout/Divider";
import Icon from "../../layout/Icon";
import SubBar from "../../layout/SubBar";
import FormButton from "../../layout/forms/FormButton";
import InputComponent from "../../layout/forms/InputComponent";
import InputDatePicker from "../../layout/forms/InputDatePicker";
import Select from "../../layout/forms/Select";
import InnerAddressForm from "../Contacts/InnerAddressForm";
import InnerEmailForm from "../Contacts/InnerEmailForm";
import InnerPhoneForm from "../Contacts/InnerPhoneForm";

const UpdateMajeur: React.FC = () => {
    const [majeur, setMajeur] = useState<IProtectedAdult>(null);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [famillyName, setFamillyName] = useState<string>("");
    const [civilityOption, setCivilityOption] = useState<any>();
    const [userId, setUserId] = useState<string>("");
    const [addresses, setAddresses] = useState<Array<IAddress>>([]);
    const [phones, setPhones] = useState<Array<IPhone>>([]);
    const [emails, setEmails] = useState<Array<IEmail>>([]);

    const [addressIndex, setAddressIndex] = useState<number>(0);
    const [phoneIndex, setPhoneIndex] = useState<number>(0);
    const [emailIndex, setEmailIndex] = useState<number>(0);

    const [numSecu, setNumSecu] = useState<string>("");
    const [numConfidentiel, setNumConfidentiel] = useState<string>("");
    const [nationality, setNationality] = useState<string>("");
    const [religion, setReligion] = useState<string>("");
    const [birthPlace, setBirthPlace] = useState<string>("");

    const [alone, setAlone] = useState<string>();
    const [animals, setAnimals] = useState<string>();
    const [notes, setNotes] = useState<string>("");
    const [teleAlarm, setTeleAlarm] = useState<string>();
    const [numGir, setNumGir] = useState<string>("");
    const [numFiscal, setNumFiscal] = useState<string>("");
    const [familyStatus, setFamilyStatus] = useState<string>();

    const [birthDate, setBirthDate] = useState<Date>();

    const history = useHistory();
    const { majeurId }: { majeurId?: string } = useParams();
    const { addToast } = useToasts();

    useEffect(() => {
        if (!userId) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setUserId(data._id);
                })
                .catch();
        }

        if (!majeur) {
            request({ addToast, url: `/api/majeur/${majeurId}` })
                .then(({ data }) => {
                    setMajeur(data);

                    setFirstName(data.first_name);
                    setFamillyName(data.familly_name);
                    setLastName(data.last_name);
                    setCivilityOption(data.civility);

                    if (data.addresses && data.addresses.length > 0) {
                        data.addresses.forEach(function (a: any, index: any) {
                            a["id"] = `Address-${index}`;
                        });

                        setAddresses(data.addresses);
                        setAddressIndex(data.addresses.length);
                    }

                    if (data.phones && data.phones.length > 0) {
                        data.phones.forEach(function (p: any, index: any) {
                            p["id"] = `phone-${index}`;
                        });

                        setPhones(data.phones);
                        setPhoneIndex(data.phones.length);
                    }

                    if (data.emails && data.emails.length > 0) {
                        data.emails.forEach(function (e: any, index: any) {
                            e["id"] = `email-${index}`;
                        });

                        setEmails(data.emails);
                        setEmailIndex(data.emails.length);
                    }

                    setNumSecu(data.n_secu);
                    setNumConfidentiel(data.n_confidentiel);
                    setNationality(data.nationality);
                    setReligion(data.religion);
                    setBirthPlace(data.birth_location);
                    setAlone(data.seul ? data.seul.toUpperCase() : "NON");
                    setAnimals(
                        data.animaux ? data.animaux.toUpperCase() : "NON"
                    );
                    setNotes(data.notes);
                    setTeleAlarm(
                        data.telealarm ? data.telealarm.toUpperCase() : "NON"
                    );
                    setNumGir(data.n_gir);
                    setNumFiscal(data.fiscal_number);
                    setFamilyStatus(
                        data.family_status ? data.family_status : "Celibataire"
                    );
                    setBirthDate(
                        data.birth_date
                            ? new Date(
                                  data.birth_date.$date
                                      ? parseInt(
                                            data.birth_date.$date.$numberLong
                                        )
                                      : data.birth_date
                              )
                            : null
                    );
                    setAddressIndex(data?.addresses.length);
                })
                .catch();
        }
    }, [majeur, userId]);

    /**
     * Update the protected adult.
     */
    const updateMajeur = () => {
        const majeurUpdated: any = {
            _id: majeurId,
            civility: civilityOption,
            last_name: capitalizeFirst(lastName.trim()),
            familly_name: famillyName
                ? capitalizeFirst(famillyName.trim())
                : "",
            first_name: capitalizeFirst(firstName.trim()),
            addresses: addresses,
            phones: phones,
            emails: emails,
            n_secu: numSecu ?? "",
            n_confidentiel: numConfidentiel ?? "",
            nationality: nationality ? capitalizeFirst(nationality.trim()) : "",
            religion: religion ? capitalizeFirst(religion.trim()) : "",
            birth_location: birthPlace
                ? capitalizeFirst(birthPlace.trim())
                : "",
            seul: alone ?? "Oui",
            animaux: animals ?? "Non",
            notes: notes ?? "",
            telealarm: teleAlarm ?? "Non",
            n_gir: numGir ?? "",
            fiscal_number: numFiscal ?? "",
            family_status: familyStatus ?? "Célibataire",
            birth_date: birthDate,
        };

        request({
            addToast,
            url: "/api/majeurs/update",
            method: "post",
            data: {
                majeur: majeurUpdated,
                userId: userId,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast("Le protégé a bien été modifié", {
                    appearance: "success",
                });
                history.push(`/single_majeur/${majeurId}`);
            })
            .catch();
    };

    //Add a Phone, Email or Adress
    const addSomething = (type: string) => {
        switch (type) {
            case "Address":
                const addressList = [...addresses];
                if (checkPrevious(addressList, "addresses")) {
                    addresses.push({
                        id: `Address-${addressIndex}`,
                        label: "",
                    });
                    setAddressIndex(addressIndex + 1);
                } else {
                    addToast("Ajoutez d'abord une addresses.", {
                        appearance: "warning",
                    });
                }
                break;

            case "Phone":
                const phonesList = [...phones];
                if (checkPrevious(phonesList, "phones")) {
                    phones.forEach((p) => (p.isDeletable = false));
                    phones.push({
                        id: `phone-${phoneIndex}`,
                        isDeletable: true,
                        label: "",
                    });
                    setPhoneIndex(phoneIndex + 1);
                } else {
                    addToast("Ajoutez d'abord un numéro de téléphone.", {
                        appearance: "warning",
                    });
                }
                break;

            case "Email":
                const emailsList = [...emails];
                if (checkPrevious(emailsList, "emails")) {
                    emails.forEach((p) => (p.isDeletable = false));
                    emails.push({
                        id: `email-${emailIndex}`,
                        isDeletable: true,
                        label: "",
                    });
                    setEmailIndex(emailIndex + 1);
                } else {
                    addToast("Ajoutez d'abord une adresse email.", {
                        appearance: "warning",
                    });
                }
                break;

            default:
                break;
        }
    };

    //Modify a Phone, Email or Adress
    const setSomethingValue = (type: string, object: any) => {
        let index = 0;
        switch (type) {
            case "Address":
                index = addresses.findIndex((a) => a.id === object.id);
                let newAddresses = addresses;
                newAddresses[index] = object;
                setAddresses(newAddresses);
                break;

            case "Phone":
                index = phones.findIndex((p) => p.id === object.id);
                let newPhones = phones;
                newPhones[index] = object;
                setPhones(newPhones);
                break;

            case "Email":
                index = emails.findIndex((e) => e.id === object.id);
                let newEmails = emails;
                newEmails[index] = object;
                setEmails(newEmails);
                break;

            default:
                break;
        }
    };

    //Delete a Phone, Email or Adress
    const removeSomething = (type: string, object: any) => {
        let index = 0;
        switch (type) {
            case "Address":
                index = addresses.findIndex((a) => a.id === object.id);
                let newAddresses = addresses;
                newAddresses.splice(index, 1);
                setAddresses(newAddresses);
                setAddressIndex(addressIndex - 1);
                break;

            case "Phone":
                index = phones.findIndex((p) => p.id === object.id);
                let newPhones = phones;
                newPhones.splice(index, 1);
                newPhones.forEach(
                    (a, index) =>
                        (a.isDeletable = index === newPhones.length - 1)
                );
                setPhones(newPhones);
                setPhoneIndex(phoneIndex - 1);
                break;

            case "Email":
                index = emails.findIndex((e) => e.id === object.id);
                let newEmails = emails;
                newEmails.splice(index, 1);
                newEmails.forEach(
                    (e, index) =>
                        (e.isDeletable = index === newEmails.length - 1)
                );
                setEmails(newEmails);
                setEmailIndex(emailIndex - 1);
                break;

            default:
                break;
        }
    };

    return (
        <>
            {majeur && (
                <div className="update_majeur">
                    <ArrowBack title="Modifier les informations" />

                    <form className="column" onSubmit={() => updateMajeur()}>
                        <div className="page_content block column">
                            <div className="row layout-xs-column">
                                <Select
                                    id="civility"
                                    label="Civilité"
                                    className="column-item-flex-1"
                                    value={civilityOption}
                                    needDefaultValue={true}
                                    options={CIVILITY_OPTIONS}
                                    required
                                    onChange={(e) => {
                                        setCivilityOption(e.target.value);
                                    }}
                                />
                                <div className="flex005 hide-xs"></div>
                                <InputComponent
                                    id="firstName"
                                    type="text"
                                    className="column-item-flex-1"
                                    label="Prénom"
                                    required
                                    value={firstName}
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                    maxLength={50}
                                />
                                <div className="flex005 hide-xs"></div>
                                <InputComponent
                                    id="lastName"
                                    type="text"
                                    className="column-item-flex-1"
                                    label="Nom d'usage"
                                    required
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                    maxLength={50}
                                />
                                <div className="flex005 hide-xs"></div>
                                <InputComponent
                                    id="famillyName"
                                    type="text"
                                    className="column-item-flex-1"
                                    label="Nom de naissance"
                                    value={famillyName}
                                    onChange={(e) =>
                                        setFamillyName(e.target.value)
                                    }
                                    maxLength={50}
                                />
                            </div>

                            <div className="flex005 hide-xs" />

                            {/* Block for addresses linked */}
                            {/* <div className="block"> */}
                            {/* <div className="my-sub-title-f"> */}
                            <SubBar title="Adresses">
                                <Link
                                    to={"#"}
                                    onClick={() => addSomething("Address")}
                                >
                                    <Icon name="add" />
                                </Link>
                            </SubBar>
                            {/* </div> */}
                            <div className="layout-center-start column">
                                {addresses.map((address, index) => (
                                    <div
                                        key={index}
                                        className="column full-width"
                                    >
                                        <InnerAddressForm
                                            key={address.id}
                                            id={address.id}
                                            removeAddress={(
                                                address: IAddress
                                            ) =>
                                                removeSomething(
                                                    "Address",
                                                    address
                                                )
                                            }
                                            onChange={(address: IAddress) =>
                                                setSomethingValue(
                                                    "Address",
                                                    address
                                                )
                                            }
                                            isDeletable={true}
                                            {...address}
                                        />
                                        {addresses.length != index + 1 && (
                                            <Divider />
                                        )}
                                    </div>
                                ))}
                            </div>

                            {/* Block for phones linked */}
                            <SubBar title="Téléphones">
                                <Link
                                    to={"#"}
                                    onClick={() => addSomething("Phone")}
                                >
                                    <Icon name="add" />
                                </Link>
                            </SubBar>
                            {/* </div> */}

                            <div className="layout-center-start column">
                                {phones.map((phone, index) => (
                                    <div
                                        key={index}
                                        className="column full-width"
                                    >
                                        <InnerPhoneForm
                                            key={phone.id}
                                            id={phone.id}
                                            removePhone={(phone: IPhone) =>
                                                removeSomething("Phone", phone)
                                            }
                                            onChange={(phone: IPhone) =>
                                                setSomethingValue(
                                                    "Phone",
                                                    phone
                                                )
                                            }
                                            isDeletable={
                                                phones.length - 1 === index
                                            }
                                            {...phone}
                                        />
                                        {phones.length != index + 1 && (
                                            <Divider />
                                        )}
                                    </div>
                                ))}
                            </div>

                            <div className="flex005 hide-xs" />

                            {/* Block for mails linked */}
                            <SubBar title="Emails">
                                <Link
                                    to={"#"}
                                    onClick={() => addSomething("Email")}
                                >
                                    <Icon name="add" />
                                </Link>
                            </SubBar>

                            <div className="layout-center-start column">
                                {emails.map((email, index) => (
                                    <div
                                        key={index}
                                        className="column full-width"
                                    >
                                        <InnerEmailForm
                                            key={email.id}
                                            id={email.id}
                                            removeEmail={(email: IEmail) =>
                                                removeSomething("Email", email)
                                            }
                                            onChange={(email: IEmail) =>
                                                setSomethingValue(
                                                    "Email",
                                                    email
                                                )
                                            }
                                            isDeletable={
                                                emails.length - 1 === index
                                            }
                                            {...email}
                                        />
                                        {emails.length != index + 1 && (
                                            <Divider />
                                        )}
                                    </div>
                                ))}
                            </div>
                            {/* </div> */}

                            <div style={{ marginTop: 10 }}>
                                <SubBar title="Informations complementaires" />
                                <div
                                    className="column"
                                    style={{ marginTop: 15 }}
                                >
                                    <div className="row layout-xs-column">
                                        <InputComponent
                                            id="numSecu"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="N° Sécurité sociale"
                                            value={numSecu}
                                            onChange={(e) =>
                                                setNumSecu(
                                                    returnOnlyNumbers(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            maxLength={15}
                                        />
                                        <div className="flex005 hide-xs" />
                                        <InputComponent
                                            id="numConfidentiel"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="N° Confidentiel si MSA"
                                            value={numConfidentiel}
                                            onChange={(e) =>
                                                setNumConfidentiel(
                                                    e.target.value
                                                )
                                            }
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className="row layout-xs-column">
                                        <InputComponent
                                            id="fiscalNumber"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="N° Fiscal"
                                            value={numFiscal}
                                            onChange={(e) => {
                                                setNumFiscal(e.target.value);
                                            }}
                                        />
                                        <div className="flex005 hide-xs" />
                                        <div className="my-input-container flex-1">
                                            <InputComponent
                                                id="nationality"
                                                type="text"
                                                className="column-item-flex-1"
                                                label="Nationalité"
                                                value={nationality}
                                                onChange={(e) =>
                                                    setNationality(
                                                        e.target.value
                                                    )
                                                }
                                                maxLength={50}
                                            />
                                        </div>
                                    </div>

                                    <div className="row layout-xs-column">
                                        <InputDatePicker
                                            id="label"
                                            inputDate={
                                                birthDate && new Date(birthDate)
                                            }
                                            label="Date de naissance "
                                            className="my-input flex-1"
                                            onChange={(date: Date) =>
                                                setBirthDate(date)
                                            }
                                        />
                                        <div className="flex005 hide-xs" />
                                        <InputComponent
                                            id="birthPlace"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Lieu de naissance"
                                            value={birthPlace}
                                            onChange={(e) =>
                                                setBirthPlace(e.target.value)
                                            }
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className="row layout-xs-column">
                                        <div className="my-input-container flex-1">
                                            <Select
                                                id="alone"
                                                label="Seul"
                                                value={alone}
                                                needDefaultValue={true}
                                                options={[
                                                    {
                                                        label: "Oui",
                                                        value: "OUI",
                                                    },
                                                    {
                                                        label: "Non",
                                                        value: "NON",
                                                    },
                                                ]}
                                                onChange={(e) => {
                                                    setAlone(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="flex005 hide-xs" />
                                        <div className="my-input-container flex-1">
                                            <Select
                                                id="familyStatus"
                                                label="Situation familiale"
                                                value={familyStatus}
                                                needDefaultValue={true}
                                                options={[
                                                    {
                                                        label: "Célibataire",
                                                        value: "Celibataire",
                                                    },
                                                    {
                                                        label: "Veuf(ve)",
                                                        value: "Veuf(ve)",
                                                    },
                                                    {
                                                        label: "Marié(e)",
                                                        value: "Marié(e)",
                                                    },
                                                    {
                                                        label: "Divorcé(e)/Séparé(e)",
                                                        value: "Divorcé(e) / Séparé(e)",
                                                    },
                                                    {
                                                        label: "Pacsé(e)",
                                                        value: "Pacsé(e)",
                                                    },
                                                    {
                                                        label: "En couple",
                                                        value: "En couple",
                                                    },
                                                ]}
                                                onChange={(e) => {
                                                    setFamilyStatus(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row layout-xs-column">
                                        <InputComponent
                                            id="numGir"
                                            type="number"
                                            className="column-item-flex-1"
                                            label="N° Gir"
                                            value={numGir}
                                            onChange={(e) => {
                                                const value = Math.max(
                                                    1,
                                                    Math.min(
                                                        6,
                                                        Number(e.target.value)
                                                    )
                                                );
                                                setNumGir(value.toString());
                                            }}
                                        />
                                        <div className="flex005 hide-xs" />
                                        <div className="my-input-container flex-1">
                                            <Select
                                                id="animals"
                                                label="Animaux"
                                                needDefaultValue={true}
                                                value={animals}
                                                options={[
                                                    {
                                                        label: "Oui",
                                                        value: "OUI",
                                                    },
                                                    {
                                                        label: "Non",
                                                        value: "NON",
                                                    },
                                                ]}
                                                onChange={(e) => {
                                                    setAnimals(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row layout-xs-column">
                                        <div className="my-input-container flex-1">
                                            <Select
                                                id="teleAlarm"
                                                label="Télé alarme"
                                                needDefaultValue={true}
                                                value={teleAlarm}
                                                options={[
                                                    {
                                                        label: "Oui",
                                                        value: "OUI",
                                                    },
                                                    {
                                                        label: "Non",
                                                        value: "NON",
                                                    },
                                                ]}
                                                onChange={(e) => {
                                                    setTeleAlarm(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="flex005 hide-xs"></div>

                                        <InputComponent
                                            id="religion"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Religion"
                                            value={religion}
                                            onChange={(e) =>
                                                setReligion(e.target.value)
                                            }
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className="my-input-container">
                                        <label>Observations :</label>
                                        <textarea
                                            className="my-textarea"
                                            value={notes || ""}
                                            onChange={(e) =>
                                                setNotes(e.target.value)
                                            }
                                            placeholder="Observations..."
                                        />
                                    </div>

                                    <FormButton
                                        onClick={updateMajeur}
                                        style={{ marginTop: 20 }}
                                        text={"Modifier"}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};
export default UpdateMajeur;
