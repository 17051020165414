import PropTypes from "prop-types";
import React from "react";

export const AlertMessage = (props: { type: string; message: string }) => {
    const { message, type } = props;
    const getClassName = () => {
        switch (type) {
            case "success":
                return "text-center border border-green-500 text-black font-bold p-4 rounded mb-4";
            case "warning":
                return "text-center border border-yellow-400 text-black font-bold p-4 rounded mb-4";
            case "alert":
                return "text-center border border-red-500 text-black font-bold p-4 rounded mb-4";
            case "info":
                return "text-center border border-red-500 text-black font-bold p-4 rounded mb-4";
            default:
                return "text-center border border-gray-300 text-black font-bold p-4 rounded mb-4";
        }
    };

    return <div className={getClassName()}>{message}</div>;
};

AlertMessage.propTypes = {
    type: PropTypes.oneOf(["success", "warning", "alert"]).isRequired,
    message: PropTypes.string.isRequired,
};
