import React, { useState } from "react";
import { InputForm } from "../../../../_shared/InputField";

export const PreControl = () => {
    const [patrimoine, setPatrimoine] = useState(0);
    const [revenu, setRevenu] = useState(0);

    return (
        <>
            <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Patrimoine"
                        type="number"
                        set={setPatrimoine}
                        value={patrimoine}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Revenu"
                        type="number"
                        set={setRevenu}
                        value={revenu}
                    />
                </div>
            </div>
            <ul>
                <li>
                    - justificatifs des revenus ou allocations perçus pendant
                    l’année ;
                </li>
                <li>
                    - justificatifs des impôts et taxes payés dans l’année, ou
                    avis de non-imposition ;
                </li>{" "}
                <li>
                    - ensemble des relevés de comptes de la période pour chacun
                    des comptes, livrets, titres ou contrats mentionnés dans le
                    compte de gestion ;
                </li>{" "}
                <li>
                    - résultat de l’interrogation des fichiers Ficoba et Ficovie
                    le cas échéant ;
                </li>
                <li> - justificatifs des dépenses supérieures à 500 euros ;</li>
                <li>
                    {" "}
                    - ordonnances du juge des tutelles relatives au patrimoine
                    de la personne protégée rendues dans l’année
                </li>
            </ul>
        </>
    );
};
