import React from "react";

export const InputForm = (props: {
    id?: any;
    name: any;
    value: any;
    set: any;
    type?: any;
    placeholder?: any;
    required?: any;
    onKeyUp?: any;
    className?: any;
    classNameWrapper?: any;
    min?: any;
    readOnly?: any;
    max?: any;
}) => {
    const {
        id,
        name,
        value,
        set,
        type,
        placeholder,
        required,
        onKeyUp,
        className,
        classNameWrapper,
        min,
        readOnly,
        max,
    } = props;

    return (
        <div className={classNameWrapper || ""}>
            {name && (
                <label className="text-left block uppercase tracking-wide text-atroposPrimary text-xs font-bold mb-2">
                    {!required ? (
                        name
                    ) : (
                        <>
                            {name} <span className="text-red-600"> *</span>
                        </>
                    )}
                </label>
            )}
            {type === "textarea" && (
                <textarea
                    id={id ? id : name}
                    className={
                        "appearance-none block w-full bg-gray-200 text-atroposPrismary rounded py-2 px-3 mb-3 leading-tight focus:outline-none"
                    }
                    onKeyUp={onKeyUp}
                    name={name}
                    onChange={(e) => {
                        set(e.target.value);
                    }}
                    placeholder={placeholder}
                    value={value}
                    readOnly={readOnly || false}
                    rows={5}
                    cols={33}
                />
            )}
            {type !== "textarea" && (
                <input
                    id={id ? id : name}
                    className={
                        type === "checkbox"
                            ? "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            : "appearance-none block w-full bg-gray-200 text-gray-700 rounded py-2 px-3 mb-3 leading-tight focus:outline-none"
                    }
                    onKeyUp={onKeyUp}
                    name={name + "test"}
                    disabled={readOnly || false}
                    readOnly={readOnly || false}
                    value={value}
                    checked={value}
                    onChange={(e) => {
                        if (type === "checkbox") {
                            set(!value);
                        } else {
                            if (type === "number") {
                                e.target.value = parseInt(e.target.value, 10);
                                if (
                                    typeof min === "number" &&
                                    e.target.value < min
                                )
                                    e.target.value = min;
                                if (
                                    typeof max === "number" &&
                                    e.target.value > max
                                )
                                    e.target.value = max;
                            }
                            if (
                                type === "text" &&
                                typeof max === "number" &&
                                e.target.value.length > max
                            ) {
                                e.target.value = e.target.value.substring(
                                    0,
                                    max
                                );
                            }
                            set(e.target.value);
                        }
                    }}
                    type={type || "text"}
                    placeholder={placeholder || ""}
                    required={required || false}
                />
            )}
        </div>
    );
};
