import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { updateCRGUser } from "../../../_api/User";
import { Button } from "../../../_shared/Button";
import ColorPicker from "../../../_shared/ColorPicker";
import FileUpload from "../../../_shared/FileUpload";
import { InputForm } from "../../../_shared/InputField";
import { request } from "../../../utils/axios-utils";
import { useAuth } from "../Login/auth.context";
import SubscriptionForm from "./SubscriptionForm";

const tribunals = [
    { id: 1, city: "Toulon" },
    { id: 2, city: "Albi" },
    { id: 3, city: "Bressuire" },
    { id: 4, city: "Niort" },
    { id: 5, city: "Paris 19ème" },
    { id: 6, city: "Paris 20ème" },
    { id: 7, city: "Paris 17ème" },
    { id: 8, city: "Paris 18ème" },
    { id: 9, city: "Paris 15ème" },
    { id: 10, city: "Paris 16ème" },
    { id: 11, city: "Paris 13ème" },
    { id: 12, city: "Paris 14ème" },
    { id: 13, city: "Paris 11ème" },
    { id: 14, city: "Paris 12ème" },
    { id: 15, city: "Paris 9ème" },
    { id: 16, city: "Paris 10ème" },
    { id: 17, city: "Paris 7ème" },
    { id: 18, city: "Paris 8ème" },
    { id: 19, city: "Paris 5ème" },
    { id: 20, city: "Paris 6ème" },
    { id: 21, city: "Paris 2ème" },
    { id: 22, city: "Paris 3ème" },
    { id: 23, city: "Saint-Benoit" },
    { id: 24, city: "Saint-Denis-de-La Réunion" },
    { id: 25, city: "Sannois" },
    { id: 26, city: "Montreuil (93)" },
    { id: 27, city: "Etampes" },
    { id: 28, city: "Belfort" },
    { id: 29, city: "Saint-Dié-des-Vosges" },
    { id: 30, city: "Les Sables-dOlonne" },
    { id: 31, city: "Fontenay-le-Comte" },
    { id: 32, city: "La Roche-sur-Yon" },
    { id: 33, city: "Redon" },
    { id: 34, city: "Saint-Malo" },
    { id: 35, city: "Arcachon" },
    { id: 36, city: "Béziers" },
    { id: 37, city: "Thonon-les-Bains" },
    { id: 38, city: "Paris 1er" },
    { id: 39, city: "Annecy" },
    { id: 40, city: "Bonneville" },
    { id: 41, city: "Albertville" },
    { id: 42, city: "Chambéry" },
    { id: 43, city: "Lure" },
    { id: 44, city: "Vesoul" },
    { id: 45, city: "Villefranche-sur-Saône" },
    { id: 46, city: "Guebwiller" },
    { id: 47, city: "Thann" },
    { id: 48, city: "Saverne" },
    { id: 49, city: "Strasbourg" },
    { id: 50, city: "Riom" },
    { id: 51, city: "Bayonne" },
    { id: 52, city: "Calais" },
    { id: 53, city: "Saint-Omer" },
    { id: 54, city: "Béthune" },
    { id: 55, city: "Boulogne-sur-Mer" },
    { id: 56, city: "Compiègne" },
    { id: 57, city: "Senlis" },
    { id: 58, city: "Valenciennes" },
    { id: 59, city: "Douai" },
    { id: 60, city: "Fougères" },
    { id: 61, city: "Cambrai" },
    { id: 62, city: "Saint-Avold" },
    { id: 63, city: "Sarrebourg" },
    { id: 64, city: "Lunéville" },
    { id: 65, city: "Verdun" },
    { id: 66, city: "Val de Briey" },
    { id: 67, city: "Cholet" },
    { id: 68, city: "Cherbourg-en-Cotentin" },
    { id: 69, city: "Villeneuve-sur-Lot" },
    { id: 70, city: "Figeac" },
    { id: 71, city: "Montbrison" },
    { id: 72, city: "Roanne" },
    { id: 73, city: "Dax" },
    { id: 74, city: "Mont-de-Marsan" },
    { id: 75, city: "Saint-Claude" },
    { id: 76, city: "Saint-Etienne" },
    { id: 77, city: "Sarreguemines" },
    { id: 78, city: "Saint-Gaudens" },
    { id: 79, city: "Aix-en-Provence" },
    { id: 80, city: "Gap" },
    { id: 81, city: "Saint-Quentin" },
    { id: 82, city: "Paris" },
    { id: 83, city: "Auch" },
    { id: 84, city: "Alès" },
    { id: 85, city: "Libourne" },
    { id: 86, city: "Bordeaux" },
    { id: 87, city: "Châteauroux" },
    { id: 88, city: "Sète" },
    { id: 89, city: "Vienne" },
    { id: 90, city: "Grenoble" },
    { id: 91, city: "Saint-Nazaire" },
    { id: 92, city: "Nantes" },
    { id: 93, city: "Cahors" },
    { id: 94, city: "Montargis" },
    { id: 95, city: "Marmande" },
    { id: 96, city: "Agen" },
    { id: 97, city: "Avranches" },
    { id: 98, city: "Mende" },
    { id: 99, city: "Chaumont" },
    { id: 100, city: "Reims" },
    { id: 101, city: "Nancy" },
    { id: 102, city: "Laval" },
    { id: 103, city: "Vannes" },
    { id: 104, city: "Lorient" },
    { id: 105, city: "Thionville" },
    { id: 106, city: "Metz" },
    { id: 107, city: "Hazebrouck" },
    { id: 108, city: "Dunkerque" },
    { id: 109, city: "Roubaix" },
    { id: 110, city: "Maubeuge" },
    { id: 111, city: "Beauvais" },
    { id: 112, city: "Tourcoing" },
    { id: 113, city: "Pau" },
    { id: 114, city: "Thiers" },
    { id: 115, city: "Condom" },
    { id: 116, city: "Belley" },
    { id: 117, city: "Brest" },
    { id: 118, city: "Soissons" },
    { id: 119, city: "Nantua" },
    { id: 120, city: "Cannes" },
    { id: 121, city: "Antibes" },
    { id: 122, city: "Menton" },
    { id: 123, city: "Grasse" },
    { id: 124, city: "Martigues" },
    { id: 125, city: "Privas" },
    { id: 126, city: "Carcassonne" },
    { id: 127, city: "Troyes" },
    { id: 128, city: "Dijon" },
    { id: 129, city: "Beaune" },
    { id: 130, city: "Caen" },
    { id: 131, city: "Salon-de-Provence" },
    { id: 132, city: "La Rochelle" },
    { id: 133, city: "Aurillac" },
    { id: 134, city: "Brive-la-Gaillarde" },
    { id: 135, city: "Saint-Amand-Montrond" },
    { id: 136, city: "Quimper" },
    { id: 137, city: "Guéret" },
    { id: 138, city: "Bastia" },
    { id: 139, city: "Ajaccio" },
    { id: 140, city: "Evreux" },
    { id: 141, city: "Saint-Brieuc" },
    { id: 142, city: "Morlaix" },
    { id: 143, city: "Chartres" },
    { id: 144, city: "Cagnes-sur-Mer" },
    { id: 145, city: "Jonzac" },
    { id: 146, city: "Charleville-Mézières" },
    { id: 147, city: "Marseille" },
    { id: 148, city: "Annemasse" },
    { id: 149, city: "Le Creusot" },
    { id: 150, city: "Fréjus" },
    { id: 151, city: "Draguignan" },
    { id: 152, city: "Clermont-Ferrand" },
    { id: 153, city: "Lens" },
    { id: 154, city: "Colmar" },
    { id: 155, city: "Schiltigheim" },
    { id: 156, city: "Bar-le-Duc" },
    { id: 157, city: "Saumur" },
    { id: 158, city: "Arras" },
    { id: 159, city: "Clamecy" },
    { id: 160, city: "Tours" },
    { id: 161, city: "Rennes" },
    { id: 162, city: "Le Puy-en-Velay" },
    { id: 163, city: "Blois" },
    { id: 164, city: "Palaiseau" },
    { id: 165, city: "Orange" },
    { id: 166, city: "Nogent-sur-Marne" },
    { id: 167, city: "Ivry-sur-Seine" },
    { id: 168, city: "Saintes" },
    { id: 169, city: "Mamoudzou" },
    { id: 170, city: "Muret" },
    { id: 171, city: "Sucy en Brie" },
    { id: 172, city: "Romans-sur-Isère" },
    { id: 173, city: "Saint-Flour" },
    { id: 174, city: "Vire Normandie" },
    { id: 175, city: "Tarascon" },
    { id: 176, city: "Lisieux" },
    { id: 177, city: "Foix" },
    { id: 178, city: "Sedan" },
    { id: 179, city: "Millau" },
    { id: 180, city: "Paris 4ème" },
    { id: 181, city: "Moulins" },
    { id: 182, city: "Narbonne" },
    { id: 183, city: "Annonay" },
    { id: 184, city: "Le Havre" },
    { id: 185, city: "Péronne" },
    { id: 186, city: "Saint-Paul" },
    { id: 187, city: "Brignoles" },
    { id: 188, city: "Poissy" },
    { id: 189, city: "Rambouillet" },
    { id: 190, city: "Saint-Germain-en-Laye" },
    { id: 191, city: "Versailles" },
    { id: 192, city: "Rouen" },
    { id: 193, city: "Fontainebleau" },
    { id: 194, city: "Lagny-sur-Marne" },
    { id: 195, city: "Melun" },
    { id: 196, city: "Mulhouse" },
    { id: 197, city: "Villeurbanne" },
    { id: 198, city: "Mâcon" },
    { id: 199, city: "Le Mans" },
    { id: 200, city: "Tarbes" },
    { id: 201, city: "Perpignan" },
    { id: 202, city: "Illkirch-Graffenstaden" },
    { id: 203, city: "Molsheim" },
    { id: 204, city: "Boulogne-Billancourt" },
    { id: 205, city: "Colombes" },
    { id: 206, city: "Courbevoie" },
    { id: 207, city: "Puteaux" },
    { id: 208, city: "Juvisy-sur-Orge" },
    { id: 209, city: "Longjumeau" },
    { id: 210, city: "Antony" },
    { id: 211, city: "Asnières-sur-Seine" },
    { id: 212, city: "Carpentras" },
    { id: 213, city: "Limoges" },
    { id: 214, city: "Epinal" },
    { id: 215, city: "Evry" },
    { id: 216, city: "Amiens" },
    { id: 217, city: "Castelsarrasin" },
    { id: 218, city: "Montauban" },
    { id: 219, city: "Avignon" },
    { id: 220, city: "Montmorency" },
    { id: 221, city: "Basse-Terre" },
    { id: 222, city: "Pointe-à-Pitre" },
    { id: 223, city: "Fort-de-France" },
    { id: 224, city: "Charenton-le-Pont" },
    { id: 225, city: "Saint-Maur-des-Fossés" },
    { id: 226, city: "Villejuif" },
    { id: 227, city: "Pantin" },
    { id: 228, city: "Le Raincy" },
    { id: 229, city: "Saint-Denis" },
    { id: 230, city: "Saint-Ouen" },
    { id: 231, city: "Vanves" },
    { id: 232, city: "Aubervilliers" },
    { id: 233, city: "Aulnay-sous-Bois" },
    { id: 234, city: "Bobigny" },
    { id: 235, city: "Pontoise" },
    { id: 236, city: "Cayenne" },
    { id: 237, city: "Toulouse" },
    { id: 238, city: "Saint-Pierre" },
    { id: 239, city: "Saint-Martin" },
    { id: 240, city: "Montélimar" },
    { id: 241, city: "Bergerac" },
    { id: 242, city: "Dreux" },
    { id: 243, city: "Vichy" },
    { id: 244, city: "Saint-Girons" },
    { id: 245, city: "Montluçon" },
    { id: 246, city: "Laon" },
    { id: 247, city: "Trévoux" },
    { id: 248, city: "Bourg en Bresse" },
    { id: 249, city: "Rochefort" },
    { id: 250, city: "Cognac" },
    { id: 251, city: "Angoulême" },
    { id: 252, city: "Aubenas" },
    { id: 253, city: "Nice" },
    { id: 254, city: "Manosque" },
    { id: 255, city: "Digne-les-Bains" },
    { id: 256, city: "Pontarlier" },
    { id: 257, city: "Montbéliard" },
    { id: 258, city: "Besançon" },
    { id: 259, city: "Sarlat-la-Canéda" },
    { id: 260, city: "Périgueux" },
    { id: 261, city: "Guingamp" },
    { id: 262, city: "Dinan" },
    { id: 263, city: "Gonesse" },
    { id: 264, city: "Montpellier" },
    { id: 265, city: "Aubagne" },
    { id: 266, city: "Uzès" },
    { id: 267, city: "Nîmes" },
    { id: 268, city: "Bernay" },
    { id: 269, city: "Les Andelys" },
    { id: 270, city: "Valence" },
    { id: 271, city: "Rodez" },
    { id: 272, city: "Saint-Dizier" },
    { id: 273, city: "Châlons-en-Champagne" },
    { id: 274, city: "Coutances" },
    { id: 275, city: "Angers" },
    { id: 276, city: "Orléans" },
    { id: 277, city: "Lons-le-Saunier" },
    { id: 278, city: "Dole" },
    { id: 279, city: "Bourgoin-Jallieu" },
    { id: 280, city: "Oloron-Sainte-Marie" },
    { id: 281, city: "Montreuil (62)" },
    { id: 282, city: "Flers" },
    { id: 283, city: "Argentan" },
    { id: 284, city: "Alençon" },
    { id: 285, city: "Lille" },
    { id: 286, city: "Avesnes-sur-Helpe" },
    { id: 287, city: "Nevers" },
    { id: 288, city: "Meaux" },
    { id: 289, city: "Bourges" },
    { id: 290, city: "Dieppe" },
    { id: 291, city: "La Flèche" },
    { id: 292, city: "Chalon-sur-Saône" },
    { id: 293, city: "Lyon" },
    { id: 294, city: "Sélestat" },
    { id: 295, city: "Haguenau" },
    { id: 296, city: "Sens" },
    { id: 297, city: "Auxerre" },
    { id: 298, city: "Poitiers" },
    { id: 299, city: "Châtellerault" },
    { id: 300, city: "Pertuis" },
    { id: 301, city: "Montbard" },
    { id: 302, city: "Abbeville" },
    { id: 303, city: "Mantes-la-Jolie" },
    { id: 304, city: "Tulle" },
    { id: 305, city: "Tribunal de test" },
    { id: 306, city: "Tribunal de Castres" },
];

function getTribunalNameById(id) {
    const tribunal = tribunals.find((t) => t.id === id);
    return tribunal ? tribunal.city : "Tribunal non trouvé";
}

export const SettingsCRG = () => {
    const { setCurrentUser, currentUser } = useAuth();

    const [companyName, setCompanyName] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.companyName : ""
    );
    const [city, setCity] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.city : ""
    );
    const [phone, setPhone] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.phone : ""
    );
    const [email, setEmail] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.email : ""
    );

    const [logo, setLogo] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.logo : null
    );
    const [signature, setSignature] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.signature : null
    );
    const [color, setColor] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.color : "#ffffff"
    );

    const { addToast } = useToasts();

    const UploadFile = async (file, type) => {
        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(
            process.env.REACT_APP_BASE_URL +
                "api/user/modify/file/" +
                type +
                "/" +
                currentUser._id,
            {
                method: "POST",
                body: formData,
                headers: {
                    Accept: "application/json",
                },
            }
        );

        if (response.ok) {
            refreshUser();
            addToast("Fichier mis en ligne", { appearance: "success" });
        }
    };

    const loadFile = async (type: string, path: any) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                    "api/user/" +
                    currentUser._id +
                    "/file/" +
                    type
            );
            if (response.ok) {
                const data = await response.json();
                if (type === "SIGNATURE") {
                    setSignature(data.url);
                }
                if (type === "LOGO") {
                    setLogo(data.url);
                }
            } else {
                console.log("Erreur lors du téléchargement du fichier.");
            }
        } catch (error) {
            console.error("Erreur lors de la récupération du fichier :", error);
        }
    };

    const refreshUser = () => {
        request({ addToast, url: `/api/user` }).then(({ data }) => {
            setCurrentUser(data);
        });
    };

    useEffect(() => {
        if (currentUser?.crg_infos?.signature) {
            loadFile("SIGNATURE", currentUser?.crg_infos?.signature);
        }
        if (currentUser?.crg_infos?.logo) {
            loadFile("LOGO", currentUser?.crg_infos?.logo);
        }
    }, []);

    const stripePromise = loadStripe("pk_test_z8LRVAmWA6FUWnHQjMuHm8mr");

    return (
        <div className="p-2">
            {currentUser.roles[0] !== "judge" && (
                <>
                    <h4>Informations</h4>
                    <div className="flex flex-wrap -mx-3 mb-3 p-2">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <InputForm
                                name="Nom de société"
                                set={setCompanyName}
                                value={companyName}
                                required={true}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <InputForm
                                name="Ville"
                                set={setCity}
                                value={city}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-3 p-2">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <InputForm
                                name="Téléphone"
                                set={setPhone}
                                value={phone}
                                required={true}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <InputForm
                                name="Email"
                                set={setEmail}
                                value={email}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="p-2">
                        <ColorPicker
                            color={color}
                            setColor={setColor}
                            refreshUser={refreshUser}
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button
                            type={"empty"}
                            name={"Enregistrer les modifications"}
                            action={() => {
                                const payload = {
                                    color,
                                    companyName,
                                    email,
                                    phone,
                                    city,
                                };

                                updateCRGUser(
                                    addToast,
                                    currentUser._id,
                                    payload
                                ).then((resp) => {
                                    addToast(resp.data.message, {
                                        appearance: "success",
                                    });
                                    setCurrentUser(resp.data.data);
                                    refreshUser();
                                });
                            }}
                        />
                    </div>
                    <div className="flex">
                        <div className="p-2">
                            <h1>Logo</h1>
                            <FileUpload
                                file={logo}
                                set={setLogo}
                                type={"LOGO"}
                                UploadFile={UploadFile}
                            />
                            <img src={logo} alt="" height={100} width={175} />
                        </div>
                        <div className="p-2">
                            <h1>Signature</h1>
                            <FileUpload
                                file={signature}
                                set={setSignature}
                                type={"SIGNATURE"}
                                UploadFile={UploadFile}
                            />
                            <img
                                src={signature}
                                alt=""
                                height={100}
                                width={175}
                            />
                        </div>
                    </div>
                    <hr />
                    <Elements stripe={stripePromise}>
                        <SubscriptionForm />
                    </Elements>
                </>
            )}
            {currentUser.roles[0] === "judge" && (
                <>
                    Vous avez actuellement accès à ces tribunaux :
                    <ul>
                        {currentUser?.supervising_courts.map((court) => {
                            return <li>- {getTribunalNameById(court)}</li>;
                        })}
                    </ul>
                </>
            )}
        </div>
    );
};
