export const base64ToFile = (
    base64Strings: string[],
    fileName: string
): File[] => {
    const files: File[] = [];

    if (base64Strings) {
        base64Strings.forEach((base64String, index) => {
            if (
                Object.prototype.toString.call(base64String) === "[object File]"
            ) {
                return base64String;
            }
            // Vérifie si la chaîne base64 commence par "data:[mimeType];base64,"
            if (base64String) {
                const match = base64String.match(/^data:(.+);base64,(.*)$/);
                if (!match) {
                    throw new Error("Invalid base64 string format");
                }

                // Extrait le mimeType et les données base64
                const mimeType = match[1];
                const dataBase64 = match[2];

                // Décodage de la chaîne base64
                const byteString = atob(dataBase64);
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const byteArray = new Uint8Array(arrayBuffer);

                // Remplissage du tableau d'octets
                for (let i = 0; i < byteString.length; i++) {
                    byteArray[i] = byteString.charCodeAt(i);
                }

                // Création d'un Blob à partir du tableau d'octets
                const blob = new Blob([arrayBuffer], { type: mimeType });

                // Création d'un objet File à partir du Blob et en ajoutant un suffixe unique au nom de fichier
                const file = new File([blob], `${fileName}_${index + 1}`, {
                    type: mimeType,
                });

                // Ajoute le fichier à la liste
                files.push(file);
            }
        });

        return files;
    }
    return null;
};
