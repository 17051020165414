import React, { useState } from "react";
import { Button } from "../../../../../_shared/Button";
import { DefaultDropdown } from "../../../../../_shared/Dropdown";
import { ToggleSwitch } from "../../../../../_shared/ToggleSwitch";
import { base64ToFile } from "../../../../../utils/tools";
import { DocUpload } from "./DocUpload";

export const Logement = (props: {
    onClose: any;
    setIsValidated: Function;
    isReadOnly: boolean;
    setData: Function;
    data: any;
}) => {
    const { onClose, setIsValidated, isReadOnly, data, setData } = props;
    const checkInformation = () => {
        return true;
    };

    const [propritary, setPropitary] = useState(
        (data && data.logement.propritary) || "HOMER"
    );
    const [propritaryDoc, setPropitaryDoc] = useState(
        (data && base64ToFile(data.logement.propritaryDoc, "Document")) || null
    );

    const [mrhBuilding, setMrhBuilding] = useState(
        (data && data.logement.mrhBuilding) || "DOMICILE"
    );
    const [mrhBuildingDoc, setMrhBuildingDoc] = useState(
        (data && base64ToFile(data.logement.mrhBuildingDoc, "MRH")) || null
    );

    const [mrh, setMrh] = useState((data && data.logement.mrh) || false);

    const _onValidation = () => {
        const res = checkInformation();
        if (res) {
            setData({
                ...data,
                ...{
                    logement: {
                        propritary,
                        propritaryDoc,
                        mrhBuilding,
                        mrhBuildingDoc,
                        mrh,
                    },
                },
            });
            onClose();
        } else {
            setIsValidated(false);
        }
    };
    return (
        <>
            <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <div className="flex">
                        <DefaultDropdown
                            name={"Situation du logement"}
                            options={[
                                { label: "Propriétaire", value: "HOMER" },
                                { label: "Locataire", value: "RENTER" },
                                {
                                    label: "En établissement",
                                    value: "IN_ESTABLISHMENT",
                                },
                            ]}
                            value={propritary}
                            readOnly={isReadOnly}
                            onChange={(e) => setPropitary(e.target.value)}
                        />
                        <DocUpload
                            show={propritary === "RENTER"}
                            value={propritaryDoc}
                            set={setPropitaryDoc}
                            isReadOnly={isReadOnly}
                            title={"Quittance de loyer, avis taxe d’habitation"}
                            uniqueId={`doc-upload-properiatry`}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                <div className="flex">
                    <ToggleSwitch
                        checked={mrh}
                        onChange={() => setMrh(!mrh)}
                        label="Avez-vous souscrit une assurance MRH ?"
                        disabled={isReadOnly}
                    />
                    <DocUpload
                        show={mrh}
                        value={mrhBuildingDoc}
                        set={setMrhBuildingDoc}
                        isReadOnly={isReadOnly}
                        title={
                            "Dernière facture d'EPHAD, attestation d'hébergement, ou attestation domicile"
                        }
                        uniqueId={`doc-upload-mrh`}
                    />
                </div>
            </div>
            {mrh && (
                <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <DefaultDropdown
                            options={[
                                { label: "A domicile", value: "DOMICILE" },
                                { label: "En institution", value: "INSTIT" },
                                { label: "Hébergé", value: "HEBERGED" },
                                {
                                    label: "Sans domicile fixe",
                                    value: "SDF",
                                },
                            ]}
                            readOnly={isReadOnly}
                            value={mrhBuilding}
                            onChange={(e) => setMrhBuilding(e.target.value)}
                        />
                    </div>
                </div>
            )}
            {!isReadOnly && (
                <div className="flex justify-end">
                    <Button name={"Suivant"} action={() => _onValidation()} />
                </div>
            )}
        </>
    );
};
