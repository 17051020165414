import React, { useState } from "react";
import { Button } from "../../../../../_shared/Button";
import { ToggleSwitch } from "../../../../../_shared/ToggleSwitch";
import { base64ToFile } from "../../../../../utils/tools";
import { DocUpload } from "./DocUpload";

export const Tribunal = (props: {
    onClose: Function;
    setIsValidated: Function;
    isReadOnly: boolean;
    setData: Function;
    data: any;
}) => {
    const { onClose, setIsValidated, isReadOnly, data, setData } = props;

    const [isJudgeContacted, setIsJudgeContacted] = useState(
        (data && data.tribunal.isJudgeContacted) || false
    );
    const [isJudgeContactedDoc, setIsJudgeContactedDoc] = useState(
        (data && base64ToFile(data.tribunal.isJudgeContactedDoc, "Fichier")) ||
            false
    );

    const checkInformation = () => {
        return true;
    };

    const _onValidation = () => {
        const res = checkInformation();
        if (res) {
            setData({
                ...data,
                ...{
                    tribunal: {
                        isJudgeContacted,
                        isJudgeContactedDoc,
                    },
                },
            });
            onClose();
        } else {
            setIsValidated(false);
        }
    };
    return (
        <>
            <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <ToggleSwitch
                        checked={isJudgeContacted}
                        onChange={() => setIsJudgeContacted(!isJudgeContacted)}
                        label="Avez-vous sollicité le juge des tutelles en cours de l'année pour l'obtention d'une ordonnance ?"
                        disabled={isReadOnly}
                    />
                    <DocUpload
                        isReadOnly={isReadOnly}
                        show={isJudgeContacted}
                        value={isJudgeContactedDoc}
                        set={setIsJudgeContactedDoc}
                        uniqueId={`doc-upload-debt`}
                        title={
                            "Dossier de surrendettement, échéancier de dettes"
                        }
                    />
                </div>
            </div>
            {!isReadOnly && (
                <div className="flex justify-end">
                    <Button name={"Suivant"} action={() => _onValidation()} />
                </div>
            )}
        </>
    );
};
