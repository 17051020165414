import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Button } from "../../../../../_shared/Button";
import DropZoneArea from "../../../Majeurs/Cloud/DropzoneArea";

export const Documents = (props: {
    onClose: any;
    setIsValidated: Function;
    isReadOnly: boolean;
    setData: Function;
    data: any;
}) => {
    const { onClose, setIsValidated, isReadOnly, data, setData } = props;

    const { addToast } = useToasts();
    const checkInformation = () => {
        if (
            AvisTax.length === 0 ||
            AvisInsurance.length === 0 ||
            crg.length === 0 ||
            bankStatements.length === 0 ||
            accountSituations.length === 0
        ) {
            addToast("Toutes les documents doivent être remplis.", {
                appearance: "error",
            });
            return true;
        }
        return true;
    };
    const _onValidation = () => {
        const res = checkInformation();
        if (res) {
            setData({
                ...data,
                ...{
                    documents: {
                        AvisTax,
                        AvisInsurance,
                        bankStatements,
                        accountSituations,
                        crg,
                        invoices,
                    },
                },
            });
            onClose();
        } else {
            setIsValidated(false);
        }
    };
    console.log(data.documents.AvisTax);
    const [AvisTax, setAvisTax] = useState(
        (data && data.documents.AvisTax) || []
    );
    const [crg, setCRG] = useState((data && data.documents.crg) || []);

    const [AvisInsurance, setAvisInsurance] = useState(
        (data && data.documents.AvisInsurance) || []
    );
    const [bankStatements, setBankStatements] = useState(
        (data && data.documents.bankStatements) || []
    );
    const [invoices, setInvoices] = useState(
        (data && data.documents.invoices) || []
    );
    const [accountSituations, setAccountSituations] = useState(
        (data && data.documents.accountSituations) || []
    );

    return (
        <>
            <div className="flex justify-between">
                <div className="w-1/3 p-6">
                    <h3>Dernis avis d'imposition</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md text-center">
                        <DropZoneArea
                            isReadOnly={isReadOnly}
                            onDrop={(acceptedFiles: any) => {
                                setAvisTax((prevFiles) => [
                                    ...prevFiles, // Keep any previously uploaded files
                                    ...acceptedFiles, // Spread in the new files only once
                                ]);
                            }}
                            onRemove={(fileToRemove) =>
                                setAvisTax((prevFiles) =>
                                    prevFiles.filter(
                                        (file) => file !== fileToRemove
                                    )
                                )
                            }
                            uploadedFiles={AvisTax}
                            accept={["application/pdf"]}
                        />
                    </div>
                </div>
                <div className="w-1/3 p-6">
                    <h3>Dernier relevé d'assurance vie </h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md">
                        <DropZoneArea
                            isReadOnly={isReadOnly}
                            onDrop={(acceptedFiles: any) => {
                                setAvisInsurance((prevFiles) => [
                                    ...prevFiles, // Keep any previously uploaded files
                                    ...acceptedFiles, // Spread in the new files only once
                                ]);
                            }}
                            onRemove={(fileToRemove) =>
                                setAvisInsurance((prevFiles) =>
                                    prevFiles.filter(
                                        (file) => file !== fileToRemove
                                    )
                                )
                            }
                            uploadedFiles={AvisInsurance}
                            accept={["application/pdf"]}
                        />
                    </div>
                </div>
                <div className="w-1/3 p-6">
                    <h3>Relevés bancaires de l'année pour chaque compte</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md">
                        <DropZoneArea
                            isReadOnly={isReadOnly}
                            onDrop={(acceptedFiles: any) => {
                                setBankStatements((prevFiles) => [
                                    ...prevFiles, // Keep any previously uploaded files
                                    ...acceptedFiles, // Spread in the new files only once
                                ]);
                            }}
                            onRemove={(fileToRemove) =>
                                setBankStatements((prevFiles) =>
                                    prevFiles.filter(
                                        (file) => file !== fileToRemove
                                    )
                                )
                            }
                            uploadedFiles={bankStatements}
                            accept={["application/pdf"]}
                        />{" "}
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="w-1/3 p-6">
                    {" "}
                    <h3>Factures supérieures à 500 euros</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md">
                        <DropZoneArea
                            isReadOnly={isReadOnly}
                            onDrop={(acceptedFiles: any) => {
                                setInvoices((prevFiles) => [
                                    ...prevFiles, // Keep any previously uploaded files
                                    ...acceptedFiles, // Spread in the new files only once
                                ]);
                            }}
                            onRemove={(fileToRemove) =>
                                setInvoices((prevFiles) =>
                                    prevFiles.filter(
                                        (file) => file !== fileToRemove
                                    )
                                )
                            }
                            uploadedFiles={invoices}
                            accept={["application/pdf"]}
                        />{" "}
                    </div>
                </div>
                <div className="w-1/3 p-6">
                    {" "}
                    <h3>Attestation bancaire de la situation des comptes</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md">
                        <DropZoneArea
                            isReadOnly={isReadOnly}
                            onDrop={(acceptedFiles: any) => {
                                setAccountSituations((prevFiles) => [
                                    ...prevFiles, // Keep any previously uploaded files
                                    ...acceptedFiles, // Spread in the new files only once
                                ]);
                            }}
                            onRemove={(fileToRemove) =>
                                setAccountSituations((prevFiles) =>
                                    prevFiles.filter(
                                        (file) => file !== fileToRemove
                                    )
                                )
                            }
                            uploadedFiles={accountSituations}
                            accept={["application/pdf"]}
                        />
                    </div>
                </div>
                <div className="w-1/3 p-6">
                    {" "}
                    <h3>Compte Rendu de Gestion (CRG) complet.</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md">
                        <DropZoneArea
                            isReadOnly={isReadOnly}
                            onDrop={(acceptedFiles: any) => {
                                setCRG((prevFiles) => [
                                    ...prevFiles, // Keep any previously uploaded files
                                    ...acceptedFiles, // Spread in the new files only once
                                ]);
                            }}
                            onRemove={(fileToRemove) =>
                                setCRG((prevFiles) =>
                                    prevFiles.filter(
                                        (file) => file !== fileToRemove
                                    )
                                )
                            }
                            uploadedFiles={crg}
                            accept={["application/pdf"]}
                        />
                    </div>
                </div>
            </div>

            {!isReadOnly && (
                <div className="flex justify-end">
                    <Button name={"Suivant"} action={() => _onValidation()} />
                </div>
            )}
        </>
    );
};
