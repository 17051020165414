import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import {
    createControl,
    editControl,
    getControl,
    uploadJudgement,
} from "../../../../_api/Controle";
import { getCourts } from "../../../../_api/Court";
import { Button } from "../../../../_shared/Button";
import { DefaultDropdown } from "../../../../_shared/Dropdown";
import { InputForm } from "../../../../_shared/InputField";
import { formatDate } from "../../../../constants/Utils";
import InputSwitch from "../../../layout/forms/InputSwitch";
import { DocUpload } from "../Control/Segment/DocUpload";

const mesureTypes = [
    { label: "Tutelle", value: "T" },
    { label: "Curatelle simple", value: "CS" },
    { label: "Curatelle renforcée", value: "CR" },
    { label: "Mandat spécial", value: "MS" },
    { label: "Mesure d'accompagnement judiciaire", value: "MAJ" },
    { label: "Mandat de protection future", value: "MPF" },
    { label: "Subrogé curateur", value: "SC" },
    { label: "Subrogé tuteur", value: "ST" },
];
export const CreateMandat = () => {
    const { addToast } = useToasts();
    const { mandat_id }: { mandat_id?: string } = useParams();

    const history = useHistory();
    const [tribunals, setTribunals] = useState([]);

    const [tribunal, setTribunal] = useState(302);
    const [typeMesure, setTypeMesure] = useState("T");

    const [numeroRG, setNumeroRG] = useState("");
    const [portalis, setPortalis] = useState("");
    const [judgmentDoc, setJudgmentDoc] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [monthDuration, setMonthDuration] = useState(0);

    const [firstLastName, setFirstLastName] = useState("");
    const [name, setName] = useState("");

    const [agentName, setAgentName] = useState("");
    const [agentMail, setAgentMail] = useState("");
    const [agentMobile, setAgentMobile] = useState("");

    const [subRogeName, setSubRogeName] = useState("");
    const [subRogeMail, setSubRogeMail] = useState("");
    const [subRogeMobile, setSubRogeMobile] = useState("");
    const [observations, setObservations] = useState("");
    const [isArchived, setisArchived] = useState(false);

    useEffect(() => {
        getCourts(addToast).then((resp) => {
            setTribunals(
                resp.data
                    .map((res: any) => {
                        return { value: res.id, label: res.city };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label))
            );
        });
        if (mandat_id) {
            getControl(addToast, mandat_id).then((resp: any) => {
                const data = resp.data;
                setAgentMail(data.tutor_mail);
                setAgentName(data.tutor_name);
                setAgentMobile(data.tutor_mobile);
                setisArchived(data.isArchived);
                setSubRogeMail(data.subroge_mail);
                setSubRogeName(data.subroge_name);
                setSubRogeMobile(data.subroge_mobile);
                setMonthDuration(data.month_duration);
                setNumeroRG(data.rg_number);
                setPortalis(data.portalis_number);
                setStartDate(formatDate(new Date(data.start_period)));
                setFirstLastName(data.name);
                setName(data.second_name);
                setObservations(data.observations);
                setTypeMesure(data.mesure_type);
            });
        }
    }, []);

    return (
        <div className="p-4">
            <div className="flex justify-between">
                <div>
                    <Button
                        type={"empty"}
                        name={"Retour"}
                        action={() => {
                            history.push("/list_mandats");
                        }}
                    />
                </div>
                <div>
                    <InputSwitch
                        id="isArchivedProtege"
                        className="column-item-flex-1"
                        onChange={() => {
                            setisArchived(!isArchived);
                        }}
                        label="Mandats"
                        isSwitchMajeur={true}
                        onText="Archivés"
                        offText="Courants"
                        value={isArchived}
                    />
                </div>
            </div>
            <div className="mt-2">
                <DefaultDropdown
                    required={true}
                    name={"Tribunal"}
                    options={tribunals}
                    value={tribunal}
                    onChange={(e) => setTribunal(e.target.value)}
                />
            </div>
            <hr className="m-4" />
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Numéro de RG"
                        set={setNumeroRG}
                        value={numeroRG}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Numéro Portalis"
                        set={setPortalis}
                        value={portalis}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Protégé : Nom et prénom"
                        set={setName}
                        value={name}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Protégé : Nom de jeune fille"
                        set={setFirstLastName}
                        value={firstLastName}
                    />
                </div>
            </div>
            <hr className="m-4" />
            <h4 className="mb-4">Informations de la mesure</h4>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Début de nomination"
                        type="date"
                        set={setStartDate}
                        value={startDate}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Durée de la mesure (mois)"
                        type="number"
                        set={setMonthDuration}
                        value={monthDuration}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <DefaultDropdown
                        required={true}
                        name={"Type de mesure"}
                        options={mesureTypes}
                        value={typeMesure}
                        onChange={(e) => setTypeMesure(e.target.value)}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0 flex items-center">
                    <DocUpload
                        show={true}
                        value={judgmentDoc}
                        set={setJudgmentDoc}
                        title={"Jugement ou extrait de jugement"}
                        uniqueId={`doc-upload-debt-consumer2`}
                    />
                </div>
            </div>
            <hr className="m-4" />
            <h4 className="mb-4">Informations des tuteurs et subroge</h4>{" "}
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Nom du tuteur/mandataire"
                        set={setAgentName}
                        value={agentName}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Email du tuteur/mandataire"
                        type="email"
                        set={setAgentMail}
                        value={agentMail}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Téléphone tuteur/mandataire"
                        set={setAgentMobile}
                        value={agentMobile}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Nom du subrogé"
                        set={setSubRogeName}
                        value={subRogeName}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Email du subroge"
                        type="email"
                        set={setSubRogeMail}
                        value={subRogeMail}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Téléphone subrogé"
                        set={setSubRogeMobile}
                        value={subRogeMobile}
                    />
                </div>
            </div>
            <hr className="m-4" />
            <InputForm
                name="Observations"
                type={"textarea"}
                set={setObservations}
                value={observations}
            />
            <div className="flex justify-end">
                <Button
                    type={"empty"}
                    name={"Enregistrer"}
                    action={() => {
                        const payload = {
                            tribunal: parseInt(tribunal),
                            rg_number: numeroRG,
                            portalis_number: portalis,
                            start_period: new Date(startDate),
                            mesure_type: typeMesure,
                            judgment_doc: judgmentDoc,
                            month_duration: monthDuration,
                            observations: observations,
                            name: firstLastName,
                            isArchived: isArchived,
                            second_name: name,
                            tutor_name: agentName,
                            tutor_mail: agentMail,
                            tutor_mobile: agentMobile,
                            subroge_name: subRogeName,
                            subroge_mail: subRogeMail,
                            subroge_mobile: subRogeMobile,
                        };

                        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (
                            agentMail &&
                            agentMail.length > 0 &&
                            !regex.test(agentMail)
                        ) {
                            addToast("Votre email est invalide", {
                                appearance: "warning",
                            });
                            return;
                        }

                        if (numeroRG.length < 3 && portalis.length < 3) {
                            addToast(
                                "Votre numéro de RG ou portalis est invalide.",
                                {
                                    appearance: "warning",
                                }
                            );
                            return;
                        }

                        if (mandat_id) {
                            editControl(addToast, payload, mandat_id).then(
                                (resp) => {
                                    if (judgmentDoc.length !== 0) {
                                        const formData = new FormData();
                                        formData.append("file", judgmentDoc[0]);

                                        uploadJudgement(
                                            addToast,
                                            formData,
                                            mandat_id
                                        ).then((resp) => {
                                            history.push("/list_mandats");
                                        });
                                    } else {
                                        history.push("/list_mandats");
                                    }

                                    addToast("Votre mandat a été modifié.", {
                                        appearance: "success",
                                    });
                                }
                            );
                        } else {
                            createControl(addToast, payload).then((resp) => {
                                if (judgmentDoc) {
                                    uploadJudgement(
                                        addToast,
                                        payload,
                                        resp.data._id
                                    ).then((resp) => {
                                        history.push("/list_mandats");
                                    });
                                }

                                addToast("Votre mandat a été crée.", {
                                    appearance: "success",
                                });
                                history.push("/list_mandats");
                            });
                        }
                    }}
                />
            </div>
        </div>
    );
};
