import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { downloadNotices } from "../../../../_api/Controle";
import { changeStatus, getDepot } from "../../../../_api/Depot";
import { Button } from "../../../../_shared/Button";
import { InputForm } from "../../../../_shared/InputField";
import { useAuth } from "../../Login/auth.context";
import { Comments } from "../Comments/Comments";
import { HistoryDepot } from "../History";
import { PreControl } from "./PreControl";
import { DepotCRG } from "./Segment/DepotCRG";

const Modal = (props: {
    isOpen: boolean;
    onClose: Function;
    actionName: string;
    action: Function;
    title: string;
}) => {
    const { isOpen, onClose, title, actionName, action } = props;
    if (!props.isOpen) return null;

    const [observations, setObservations] = useState("");
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6">
                <div className="flex justify-between items-center border-b pb-3">
                    <h3 className="text-xl font-semibold text-gray-800">
                        {title}
                    </h3>
                    <button
                        onClick={() => onClose()}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        &times;
                    </button>
                </div>

                <div className="mt-4 text-gray-600">
                    <InputForm
                        name="Observations"
                        type="textarea"
                        set={setObservations}
                        value={observations}
                        required={true}
                    />
                </div>

                {/* Modal Footer */}
                <div className="mt-6 flex justify-end space-x-4">
                    <button
                        onClick={() => onClose()}
                        className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                    >
                        Annuler
                    </button>
                    <button
                        onClick={() => action(observations)}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                        {actionName}
                    </button>
                </div>
            </div>
        </div>
    );
};

const StatusControle = {
    SUCCESS: "Contrôle Valide",
    PENDING: "En cours",
    WAITING_DEPOT: "En attente de dépot",
    WAITING_VERIF: "En attente de votre controle",
    FAILED: "Contrôle Refusé",
    REVISION: "En attente de justificatif",
};

export const CRGControl = () => {
    const { depot_id }: { depot_id: string } = useParams();
    const history = useHistory();
    const { addToast } = useToasts();

    const { currentUser } = useAuth();
    const [isModalOpen, setModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("depot");
    const [actionName, setActionName] = useState(null);
    const [action, setAction] = useState(null);
    const [title, setTitle] = useState("");

    const [depot, setDepot] = useState(null);

    const handleTabChange = (tab: React.SetStateAction<string>) => {
        setActiveTab(tab);
    };

    const refreshEntity = () => {
        getDepot(addToast, depot_id).then((resp: any) => {
            console.log(resp.data);
            setDepot(resp.data);
        });
    };

    useEffect(() => {
        refreshEntity();
    }, [depot_id]);

    return (
        <div className="p-4">
            <div className="flex justify-between">
                <Button
                    type={"empty"}
                    name={"Retour"}
                    action={() => {
                        history.push("/list_controls");
                    }}
                />
                {depot && !["SUCCESS", "FAILED"].includes(depot.status) && (
                    <div className="flex justify-end">
                        <div className="mr-2">
                            <Button
                                type={"empty"}
                                name={"Lien public"}
                                action={() => {
                                    window.open(
                                        process.env.REACT_APP_BASE_URL +
                                            "verification/" +
                                            depot_id
                                    );
                                }}
                            />
                        </div>
                        <div className="mr-2">
                            <Button
                                type={"empty"}
                                name={"Voir mandat"}
                                action={() => {
                                    history.push(
                                        "/mandat/" + depot.control._id
                                    );
                                }}
                            />
                        </div>
                        {currentUser.roles[0] !== "judge" && (
                            <>
                                <div className="mr-2">
                                    <Button
                                        type={"red"}
                                        name={"Refuser"}
                                        action={() => {
                                            setTitle(
                                                "Notes à envoyer à la personne controlée"
                                            );
                                            setActionName(
                                                "Envoyer votre refus"
                                            );
                                            setAction(
                                                () =>
                                                    (observations: string) => {
                                                        const payload = {
                                                            status: "FAILED",
                                                            observations,
                                                        };

                                                        changeStatus(
                                                            addToast,
                                                            payload,
                                                            depot_id
                                                        ).then((resp) => {
                                                            addToast(
                                                                resp.data
                                                                    .message,
                                                                {
                                                                    appearance:
                                                                        "success",
                                                                }
                                                            );
                                                            refreshEntity();
                                                            setModalOpen(false);
                                                        });
                                                    }
                                            );
                                            setModalOpen(true);
                                        }}
                                    />
                                </div>
                                <div className="mr-2">
                                    <Button
                                        type={"orange"}
                                        name={"Demander une correction"}
                                        action={() => {
                                            setTitle(
                                                "Notes à envoyer à la personne controlée"
                                            );

                                            setActionName(
                                                "Envoyer vos demandes de corrections"
                                            );
                                            setAction(
                                                () =>
                                                    (observations: string) => {
                                                        const payload = {
                                                            status: "REVISION",
                                                            observations,
                                                        };
                                                        changeStatus(
                                                            addToast,
                                                            payload,
                                                            depot_id
                                                        ).then((resp) => {
                                                            addToast(
                                                                resp.data
                                                                    .message,
                                                                {
                                                                    appearance:
                                                                        "success",
                                                                }
                                                            );
                                                            refreshEntity();
                                                            setModalOpen(false);
                                                        });
                                                    }
                                            );
                                            setModalOpen(true);
                                        }}
                                    />
                                </div>
                                <div className="mr-2">
                                    <Button
                                        type={"vert"}
                                        name={"Approuver"}
                                        action={() => {
                                            setTitle("Observations");
                                            setActionName("Approuver");
                                            setAction(
                                                () =>
                                                    (observations: string) => {
                                                        const payload = {
                                                            status: "SUCCESS",
                                                            observations,
                                                        };

                                                        changeStatus(
                                                            addToast,
                                                            payload,
                                                            depot_id
                                                        ).then((resp) => {
                                                            addToast(
                                                                resp.data
                                                                    .message,
                                                                {
                                                                    appearance:
                                                                        "success",
                                                                }
                                                            );
                                                            refreshEntity();
                                                            setModalOpen(false);
                                                        });
                                                    }
                                            );
                                            setModalOpen(true);
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )}
                {depot && (
                    <>
                        {" "}
                        {depot.status === "SUCCESS" && (
                            <div className="mr-2">
                                <Button
                                    type={"vert"}
                                    name={"Attestation approbation"}
                                    action={() => {
                                        downloadNotices(
                                            "APPROBATION",
                                            depot_id
                                        ).then((resp) => {
                                            const file = new Blob([resp.data], {
                                                type: "application/pdf",
                                            });
                                            const fileURL =
                                                URL.createObjectURL(file);
                                            window.open(fileURL, "_blank");
                                            URL.revokeObjectURL(fileURL);
                                        });
                                    }}
                                />
                            </div>
                        )}
                        {depot.status === "FAILED" && (
                            <div className="mr-2">
                                <Button
                                    type={"red"}
                                    name={"Rapport de difficulté"}
                                    action={() => {
                                        downloadNotices(
                                            "DIFFICULTY",
                                            depot_id
                                        ).then((resp) => {
                                            const file = new Blob([resp.data], {
                                                type: "application/pdf",
                                            });
                                            const fileURL =
                                                URL.createObjectURL(file);
                                            window.open(fileURL, "_blank");
                                            URL.revokeObjectURL(fileURL);
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            {depot && (
                <div className="flex justify-center">
                    <div className="font-bold text-xl text-center mt-6">
                        {depot.control.name ||
                            "" + " " + depot.control.second_name}{" "}
                        - {depot.control.rg_number} -{" "}
                        {depot.control.portalis_number}{" "}
                        <p className="text-xs italic text-center">
                            Crée le :{" "}
                            {new Date(depot.created_at).toLocaleDateString()} -
                            Période du{" "}
                            {new Date(depot.start_period).toLocaleDateString()}{" "}
                            au{"  "}
                            {new Date(depot.end_period).toLocaleDateString()}
                        </p>
                        <p className="text-xs italic text-center"></p>
                        <p className="text-xs italic text-center">
                            {StatusControle[depot.status]}
                        </p>
                    </div>
                </div>
            )}

            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                    <li className="me-2">
                        <a
                            href="#"
                            onClick={() => handleTabChange("depot")}
                            className={
                                activeTab === "depot"
                                    ? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                                    : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                            }
                        >
                            Formulaire
                        </a>
                    </li>
                    <li className="me-2">
                        <a
                            href="#pre-controle"
                            onClick={() => handleTabChange("control")}
                            className={
                                activeTab === "control"
                                    ? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                                    : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                            }
                            aria-current="page"
                        >
                            Pré-Controle
                        </a>
                    </li>
                    <li className="me-2">
                        <a
                            href="#"
                            onClick={() => handleTabChange("history")}
                            className={
                                activeTab === "history"
                                    ? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                                    : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                            }
                            aria-current="page"
                        >
                            Historique des modifications
                        </a>
                    </li>
                    <li className="me-2">
                        <a
                            href="#"
                            onClick={() => handleTabChange("messages")}
                            className={
                                activeTab === "messages"
                                    ? "inline-block p-4 text-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                                    : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                            }
                        >
                            Discussions{" "}
                            {depot && "(" + depot.messages.length + ")"}
                        </a>
                    </li>
                </ul>
            </div>
            {activeTab === "control" && <PreControl />}

            {activeTab === "history" && <HistoryDepot data={depot.history} />}
            {activeTab === "depot" && depot && (
                <DepotCRG data={depot} readOnly={true} />
            )}
            {activeTab === "messages" && depot && (
                <div className="mt-4">
                    <Comments
                        messages={depot.messages}
                        type={"DEPOT"}
                        depot_id={depot_id}
                        refreshEntity={refreshEntity}
                    />
                </div>
            )}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                actionName={actionName}
                action={action}
                title={title}
            />
        </div>
    );
};
