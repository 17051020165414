import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Button } from "../../../../../_shared/Button";
import { DefaultDropdown } from "../../../../../_shared/Dropdown";
import { InputForm } from "../../../../../_shared/InputField";
import { ToggleSwitch } from "../../../../../_shared/ToggleSwitch";

export const SituationMaritale = (props: {
    onClose: Function;
    setIsValidated: Function;
    isReadOnly: boolean;
    setData: Function;
    data: any;
}) => {
    const { onClose, setIsValidated, isReadOnly, setData, data } = props;
    const { addToast } = useToasts();
    const checkInformation = () => {
        if (maritalType === "VEUF" && !yearDeath) {
            addToast("L'année du décès du conjoint est à remplir.", {
                appeareance: "warning",
            });
            return false;
        }
        return true;
    };

    const [maritalType, setMaritalType] = useState(
        (data && data.situation_marital.maritalType) || "MARIED"
    );
    const [askedPension, setAskedPension] = useState(
        (data && data.situation_marital.askedPension) || false
    );
    const [yearDeath, setYearDeath] = useState(
        (data && data.situation_marital.yearDeath) || null
    );

    const [isDivorced, setIsDivorced] = useState(
        (data && data.situation_marital.isDivorced) || false
    );

    const [eatPension, setEatPension] = useState(
        (data && data.situation_marital.eatPension) || false
    );
    const [prestaComp, setPrestaComp] = useState(
        (data && data.situation_marital.prestaComp) || false
    );
    const [nbChild, setNbChild] = useState(
        (data && data.situation_marital.nbChild) || 0
    );

    const _onValidation = () => {
        const res = checkInformation();
        if (res) {
            setData({
                ...data,
                ...{
                    situation_marital: {
                        maritalType,
                        askedPension,
                        yearDeath,
                        isDivorced,
                        eatPension,
                        prestaComp,
                        nbChild,
                    },
                },
            });
            onClose();
        } else {
            setIsValidated(false);
        }
    };

    return (
        <>
            <div className="mt-4 mb-4">
                <DefaultDropdown
                    name={"Situation maritale"}
                    readOnly={isReadOnly}
                    options={[
                        { label: "Marié", value: "MARIED" },
                        { label: "Pacsé", value: "PACSED" },
                        { label: "Concubinage", value: "CONCUBIN" },
                        { label: "Célibataire", value: "CELIB" },
                        { label: "Veuf(ve)", value: "VEUF" },
                    ]}
                    value={maritalType}
                    onChange={(e) => setMaritalType(e.target.value)}
                />
            </div>

            {maritalType === "VEUF" && (
                <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <ToggleSwitch
                            checked={askedPension}
                            onChange={() => setAskedPension(!askedPension)}
                            label="Pension de reversion demandé ?"
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <InputForm
                            name="Années du décès du conjoint"
                            type="number"
                            set={setYearDeath}
                            value={yearDeath}
                            readOnly={isReadOnly}
                        />
                    </div>
                </div>
            )}
            {maritalType === "MARIED" && (
                <>
                    <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <ToggleSwitch
                                checked={isDivorced}
                                onChange={() => setIsDivorced(!isDivorced)}
                                label="Divorce"
                                disabled={isReadOnly}
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <ToggleSwitch
                        checked={eatPension}
                        onChange={() => setEatPension(!eatPension)}
                        label="Pension alimentaire"
                        disabled={isReadOnly}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <ToggleSwitch
                        checked={prestaComp}
                        onChange={() => setPrestaComp(!prestaComp)}
                        label="Pension de compensation"
                        disabled={isReadOnly}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="# d'enfants à charge"
                        type="number"
                        set={setNbChild}
                        value={nbChild}
                        readOnly={isReadOnly}
                    />
                </div>
            </div>

            {!isReadOnly && (
                <div className="flex justify-end">
                    <Button name={"Suivant"} action={() => _onValidation()} />
                </div>
            )}
        </>
    );
};
