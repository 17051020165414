import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
    downloadInvoice,
    getInvoicingMajeur,
} from "../../../_api/InvoicingMajeur";

export const CRGInvoicing = () => {
    const { addToast } = useToasts();
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        getInvoicingMajeur(addToast).then((resp) => {
            setInvoices(resp.data);
        });
    }, []);

    const [selectedCategory, setSelectedCategory] = useState(""); // Filtre de catégorie
    const filteredData = selectedCategory
        ? invoices.filter((item) => item.category === selectedCategory)
        : invoices;

    const [currentPage, setCurrentPage] = useState(1); // Page actuelle
    const [itemsPerPage] = useState(10); // Nombre de lignes par page

    // Calculer le nombre total de pages
    const totalPages = Math.ceil(invoices.length / itemsPerPage);

    // Déterminer les éléments à afficher pour la page actuelle
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Changer de page
    const paginate = (pageNumber: React.SetStateAction<number>) =>
        setCurrentPage(pageNumber);

    return (
        <div className="bg-gray-50 dark:bg-gray-900">
            <div className="container max-w-6xl px-5 mx-auto mt-2">
                <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="p-5 bg-white rounded shadow-sm">
                        <div className="text-base text-gray-400 ">
                            CA Total{" "}
                        </div>
                        <div className="flex items-center pt-1">
                            <div className="text-2xl font-bold text-gray-900 ">
                                €
                                {invoices
                                    .reduce(
                                        (acc, invoice) => acc + invoice.amount,
                                        0
                                    )
                                    .toFixed(2)}{" "}
                                HT{" "}
                            </div>
                        </div>
                    </div>
                    <div className="p-5 bg-white rounded shadow-sm">
                        <div className="text-base text-gray-400 ">
                            CA sur 30 jours
                        </div>
                        <div className="flex items-center pt-1">
                            <div className="text-2xl font-bold text-gray-900 ">
                                €
                                {invoices
                                    .filter((invoice) => {
                                        const thirtyDaysAgo = new Date();
                                        thirtyDaysAgo.setDate(
                                            thirtyDaysAgo.getDate() - 30
                                        );
                                        return (
                                            new Date(invoice.created_at) >=
                                            thirtyDaysAgo
                                        );
                                    })
                                    .reduce(
                                        (acc, invoice) => acc + invoice.amount,
                                        0
                                    )
                                    .toFixed(2)}
                                HT
                            </div>
                        </div>
                    </div>
                    <div className="p-5 bg-white rounded shadow-sm">
                        <div className="text-base text-gray-400 ">
                            # de CRG vérifié
                        </div>
                        <div className="flex items-center pt-1">
                            <div className="text-2xl font-bold text-gray-900 ">
                                {invoices.length}
                            </div>
                        </div>
                    </div>
                    {/* <div className="p-5 bg-white rounded shadow-sm">
                        <div className="text-base text-gray-400 ">
                            # de dépots actifs
                        </div>
                        <div className="flex items-center pt-1">
                            <div className="text-2xl font-bold text-gray-900 ">
                                1
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Date de facture
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Numéro de RG
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Portalis
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Représentant
                                </th>

                                <th scope="col" className="px-6 py-3">
                                    Patrimoine
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Revenus
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Facture HT
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr
                                    key={index}
                                    className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                >
                                    <td className="px-6 py-4">
                                        {new Date(
                                            item.created_at
                                        ).toLocaleDateString()}
                                    </td>
                                    <td className="px-6 py-4">
                                        {item.control.rg_number}
                                    </td>
                                    <td className="px-6 py-4">
                                        {item.control.portalis_number}
                                    </td>
                                    <td className="px-6 py-4">
                                        {item.control.name ||
                                            "" + " " + item.control.second_name}
                                    </td>

                                    <td className="px-6 py-4">0.00</td>
                                    <td className="px-6 py-4">0.00</td>

                                    <td className="px-6 py-4">
                                        €{" " + item.amount.toFixed(2)}
                                    </td>

                                    <td className="px-6 py-4">
                                        <a
                                            href="#"
                                            onClick={() => {
                                                console.log(item);
                                                const payload = {
                                                    item,
                                                };
                                                downloadInvoice(
                                                    item._id,
                                                    payload
                                                ).then((resp: any) => {
                                                    const file = new Blob(
                                                        [resp.data],
                                                        {
                                                            type: "application/pdf",
                                                        }
                                                    );

                                                    const fileURL =
                                                        URL.createObjectURL(
                                                            file
                                                        );

                                                    window.open(
                                                        fileURL,
                                                        "_blank"
                                                    );

                                                    URL.revokeObjectURL(
                                                        fileURL
                                                    );
                                                });
                                            }}
                                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2"
                                        >
                                            Télécharger
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            {currentItems.length === 0 && (
                                <tr className=" text-center odd:bg-white font-bold border-b dark:border-gray-700 h-12">
                                    <td colSpan={8}>
                                        Il n'y a actuellement aucune facture de
                                        réaliser.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => paginate(index + 1)}
                            className={`px-4 py-2 mx-1 text-sm font-medium text-white ${
                                currentPage === index + 1
                                    ? "bg-blue-500"
                                    : "bg-gray-700"
                            } rounded-md hover:bg-blue-700`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
